import Vue from 'vue'

import App from './App.vue'
import router from './router'
import apolloProvider from './lib/ApolloProvider'
import store from './store'


import Toast from './lib/Toast'
Vue.use(Toast.Toast, Toast.options)
Vue.mixin({
  methods: {
    errorToast: Toast.errorToast,
    errorsToast: Toast.errorsToast,
  },
})

import KProgress from 'k-progress'
Vue.component('k-progress', KProgress)


Vue.config.productionTip = false

new Vue({
  router,
  apolloProvider: apolloProvider,
  store: store,
  render: h => h(App)
}).$mount('#app')
