<template lang="pug">
div#app(v-bind:class="{small_screen: isSmallScreen}")
  router-view(:key="$route.fullPath")
</template>

<script>

import config from './appConfig'

export default {
  name: 'App',
  computed: {

  },
  data () {
    return {
      isSmallScreen: false,
    }
  },
  methods: {
    onResize() {
      // console.log('window has been resized', event)
      this.isSmallScreen = config.isSmallScreen()
      this.$emit('is-small-screen', this.isSmallScreen)
    },
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize)
  },
  mounted: function() {
    window.addEventListener('resize', this.onResize)
    this.isSmallScreen = config.isSmallScreen()
  }
}
</script>

<style>
/* *********************************
  http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
********************************* */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, button, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
  overflow-x: hidden;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

textarea:focus, input:focus{
    outline: none;
}

/* *********************************
  app global styles
********************************* */

#index_loading {
  display: none;
}

html {
  margin: 0px 0px 0px 0px !important;
  font-family: 'Inter', helvetica, arial, sans-serif;
}

.container {
  position: relative;
  z-index: 1000;
  width: 96%;
  padding: 2% 2%;
}

.small_screen .container {
  padding: 3% 3%;
  width: 94%;
}

.wrapper {
  position: relative;
  text-align: center;
}

.clickable {
  cursor: pointer;
}

button {
  cursor: pointer;
  background-color: #f4f4f7;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  font-weight: 800;
  font-size: 16px;
  color: #35393C;
  text-align: center;
  padding: 12px 36px;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

button:hover {
  background-color: #e1e1e7;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.center {
  text-align: center;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
}

button.cta {
  background-image: radial-gradient(3% -5%, #FFBE7C 0%, #EA7490 33%, #C33589 68%, #7C149C 100%);
  background-color: #3B00CD;
  box-shadow: 8px 16px 40px 0 rgba(0,0,0,0.13);
  -webkit-box-shadow: 8px 16px 40px 0 rgba(0,0,0,0.13);
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  font-weight: 800;
  font-size: 24px;
  color: #FFFFFF;
  padding: 20px 44px;
  margin-top: 20px;
}

.google_logo {
  background: url("/google_logo_transparent.svg") no-repeat left 18px bottom 50%;
  background-color: #f4f4f7;
}


.input_container {
  margin: 18px 0px 8px 0;

  background-color: #F6F6F7;
  border-radius: 18px;
  /* border: 1px solid #D8DCE8; */
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
}

.input_container input,
.input_container textarea  {
  background-color: #F6F6F7;
  text-align: left;
  padding: 26px 4%;
  margin: 0;
  border: none;
  font-size: 18px;
  font-weight: 400;
  color: #2D333D;
  overflow-y: hidden;
  overflow-x: scroll;
  width: 92%;
  font-weight: 200;
  border-radius: 18px;
  /* border: 1px solid #D8DCE8; */
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
}


.input_container input:-webkit-autofill,
.input_container input:-webkit-autofill:hover,
.input_container input:-webkit-autofill:active,
.input_container input:-webkit-autofill:focus
  {
  background-color: #F6F6F7 !important;
  box-shadow: 0 0 0 60px #F6F6F7 inset !important;
  -webkit-box-shadow: 0 0 0 60px #F6F6F7 inset !important;
  border: none !important;
}

/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

a {
  cursor: pointer;
  color: #ffffff;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

a:hover {
  text-decoration: underline;
}

.loading  {
  text-align: center;
}

.loading img {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.clear {
  clear: both;
}

.select_input_device_container {
  position: absolute;
  z-index: 800;
  bottom: 20%;
  left: 2%;
  width: 260px;
  border: none;
  text-align: left;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
