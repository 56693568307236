import Toast, { POSITION } from "vue-toastification"
import Vue from 'vue'

// Import the CSS or use your own!
import "vue-toastification/dist/index.css"

const options = {
    // You can set your default options here
    timeout: 2500,
    position: POSITION.TOP_CENTER,
    hideProgressBar: true,
}

const errorsToast = function (errors) {
    if (!errors || errors.length <= 0) return
    errors.forEach((error) => {
      Vue.$toast.error(error.detail)
    })
  }

const errorToast = function (error) {
    if (!error) return
    Vue.$toast.error(error.message, {
      timeout: 7000
    })
  }


export default { Toast, options, errorsToast, errorToast }