import Vue from 'vue'
import Vuex from 'vuex'
// import Cookies from 'js-cookie';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      // getState: (key) => {
      //   return Cookies.getJSON(key);
      // },
      // setState: (key, state) => {
      //   Cookies.set(key, state, { expires: 3, secure: true }); // 3 days
      // },
      // storage: {
      //   getItem: (key) => Cookies.get(key),
      //   // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
      //   setItem: (key, value) =>
      //     Cookies.set(key, value, { expires: 3, secure: true }),
      //   removeItem: (key) => Cookies.remove(key),
      // },
    }),
  ],
  modules: {
  },
  state: {
    token: null,
    quiz: null,
  },
  getters: {
  },
  mutations: {
    setToken(state, newToken) {
      state.token = newToken
    },
    clearToken(state) {
      state.token = null
    },
    setQuiz(state, newQuiz) {
      state.quiz = newQuiz
    },
    clearQuiz(state) {
      state.quiz = null
    },
  },
  actions: {
    setToken(context, new_token) {
      context.commit('setToken', new_token)
    },
    setQuiz(context, newQuiz) {
      context.commit('setQuiz', newQuiz)
    },
    clearQuiz({ commit }) {
      commit('clearQuiz')
    },
  }
})

export default store