import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/quiz',
    name: 'Quiz',
    props: true,
    component: () => import(/* webpackChunkName: "Home" */ '../views/Quiz.vue')
  },
  {
    path: '/read',
    name: 'Read',
    props: true,
    component: () => import(/* webpackChunkName: "Home" */ '../views/Read.vue')
  },
  {
    path: '/results',
    name: 'Results',
    props: true,
    component: () => import(/* webpackChunkName: "Home" */ '../views/Results.vue')
  },
  {
    path: '/tweet/:token',
    name: 'Tweet',
    props: true,
    component: () => import(/* webpackChunkName: "Home" */ '../views/Tweet.vue')
  },
  {
    path: '/tweet',
    name: 'Tweet',
    props: true,
    component: () => import(/* webpackChunkName: "Home" */ '../views/Tweet.vue')
  },
  {
    path: '/:slug',
    name: 'Home',
    props: true,
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/',
    name: 'Home',
    props: true,
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
